<template>
    <v-row class="acceptConditions">
        <ul>
            <h1>Betingelser for brug af Norfors' genbrugspladser i udvidet åbningstid:</h1>
            
            <li>
                Det er muligt at benytte den udvidede åbningstid på følgende pladser: <br>
                <ul>
                    <li>Genbrugsplads Allerød</li>
                    <li>Genbrugsplads Bakkegårdsvej</li>
                    <li>Genbrugsplads Blokken</li>
                    <li>Genbrugsplads Containerhaven</li>
                    <li>Genbrugsplads Vandtårnsvej</li>
                </ul>
            </li>
            <li>
                Det er nummerpladen på din bil, som giver adgang til genbrugspladsen i den ubemandede åbningstid. Det sker vha. kameraer med nummerpladeskannere, som sørger for at åbne og lukke portene.
            </li>
            <li>
                Genbrugspladsen er videoovervåget.
            </li>
            <li>
                Da den udvidede åbningstid er ubemandet, skal du selv sørge for korrekt sortering af affaldet. Er du i tvivl, kan du bruge <a href="https://norfors.dk/borger/sorteringsvejledning/"  target="_blank">Norfors' sorteringsguide</a> eller komme indenfor den bemandede åbningstid
            </li>
            <li>
                Bevidst eller ubevidst fejlsortering af affald, klunsning og/eller hærværk, kan resultere i udelukkelse fra selvbetjeningsordningen samt erstatningskrav og/eller politianmeldelse
            </li>
            <li>
                Følgende affald modtages ikke i den ubemandede åbningstid: <br>
                <ul>
                    <li>Farligt affald (fraktion 60)</li>
                    <li>Eternit og ikke-støvende asbest (fraktion 31)</li>
                    <li>Deponi (fraktion 32)</li>
                    <li>Elektronikaffald, herunder: <br>
                        <ul>
                            <li>Akkumulatorer/batterier (fraktion 48)</li>
                            <li>Kabler (fraktion 49)</li>
                            <li>Små husholdningsapparater (fraktion 52)</li>
                            <li>Skærme og monitorer (fraktion 53)</li>
                            <li>Mellemstore husholdningsapparater (fraktion 54)</li>
                            <li>Batterier (fraktion 62)</li>
                            <li>Lyskilder (fraktion 63)</li>
                            <li>Printertoner og blækpatroner (fraktion 64)</li>
                        </ul>
                    </li>
                </ul>
            </li>
            
            <li>
                Med accept af disse betingelser accepterer jeg at Norfors kontakter mig på mail/SMS om driftsinformation vedr. genbrugspladserne
            </li>
            <li>
                Tilmeldingen gælder for et år ad gangen. Efter et år vil du modtage besked om at tilmeldingen er tæt på at udløbe, og der skal foretages ny tilmelding. <br>
                Læs mere på <a href="norfors.dk/borger/udvidede-aabningstider/" target="_blank">norfors.dk/borger/udvidede-aabningstider/</a>
            </li>
            <li>
                Ordensregler for brug af Norfors' genbrugspladser skal overholdes. <br>  
                Se ordensreglerne hér: <br>
                <a href="https://norfors.dk/borger/ordensregler/" target="_blank">https://norfors.dk/borger/ordensregler/</a>
            </li>
            
            <v-checkbox
                v-model="checkboxAccept"
                label="Jeg accepterer ovenstående betingelser."
            />
            <v-btn
                :disabled="!checkboxAccept"
                color="#75ad40"
                @click="$emit('acceptConditions')"
            >
                Accepter
            </v-btn>
            
        </ul>
    </v-row>
</template>


<script>

export default {
    data() {
        return {
            checkboxAccept: false
        }
    }
}

</script>